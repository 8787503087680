$bgDarkColor: #38373d;

//Validation label colors
$validationLabelColor: red;

:root {
    --yelow-color: #f99506;
    --white-color: #ffffff;
}

body {
    background: #f5fafa !important;
    color: var(--white-color) !important;
}

.Field_data {
    font-size: 10px;
    color: #adadad;
}

//fahad
.no-header .p-datatable-thead {
    display: none;
    height: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
}

.ProfileImg {
    width: 20%;
}

.no-header .p-datatable .p-datatable-header {
    padding: 0px !important;
}

//fahad
.no-header .p-datatable-thead {
    display: none;
    height: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
}

.no-header .p-datatable .p-datatable-header {
    padding: 0px !important;
}

.Field_heading {
    font-size: 12px;
    color: #fff;
}

.p-datatable .p-datatable-header {
    background-color: none !important;
    color: red;
}

.p-sidebar-xl {
    width: 95% !important;
}

.Border-right {
    border-right: 1px dashed;
    border-color: var(--yelow-color);
}

.Border-bottom {
    border-bottom: 1px dashed;
    border-color: var(--yelow-color);
}

.Personal_information {
    font-weight: 800;
    color: var(--yelow-color);
}

/* width */
.layout-sidebar::-webkit-scrollbar {
    width: 20px;
}

/* Track */
.layout-sidebar::-webkit-scrollbar-track {
    background: var(--surface-card);
}

/* Handle */
.layout-sidebar::-webkit-scrollbar-thumb {
    background: var(--yelow-color);
    border-radius: 20px;
}

/* Handle on hover */
.layout-sidebar::-webkit-scrollbar-thumb:hover {
    background: var(--yelow-color);
}

.bg-yellow .p-dropdown {
    background-color: var(--yelow-color) !important;
}

.bg-yellow .p-dropdown-label.p-placeholder,
.bg-yellow .pi-chevron-down {
    color: #ffffff !important;
}

input[type="date"]::webkit-calendar-picker-indicator {
    filter: invert(1) !important;
}

.card h5 {
    color: var(--surface-overlay);
}

.systemSetting-Card {
    border: 1px solid var(--yelow-color);
    border-radius: 20px;
    padding: 1rem 3rem;
    background-color: var(--gray-700);
    width: 300px;
    cursor: pointer;
}

.accordian-custom .p-accordion-header-link,
.accordian-custom .p-accordion-content {
    background-color: transparent !important;
    color: white !important;
    border: none !important;
}

.accordian-custom .p-accordion-content {
    margin-left: 3rem !important;
}

.CircularProgressbar-path {
    stroke: var(--yelow-color) !important;
    box-shadow: 0 0 0.4 #3a3a3c;
    border-radius: none !important;
}

.border-bottom {
    border-bottom: 2px solid black;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke-linecap: square !important;
}

.CircularProgressbar:hover {
    cursor: pointer;
}

@media (max-width: 768px) {

    .Border-right,
    .Border-bottom {
        border-right: 0;
        border-bottom: 0;
    }
}

// .p-datatable-responsive-scroll {
//     overflow: auto;
//     padding-top: 0;
// }

.p-datatable .p-datatable-header {
    background-color: transparent !important;
}

.p-datatable .p-datatable-header {
    background: solid rgb(34 39 51) !important;
    color: #343a40 !important;
    border: none !important;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
}

.p-datatable {
    .p-datatable-thead>tr>th {
        text-align: left;
        padding: 1rem 1rem;
        border: none !important;
        border-width: 0 0 1px 0;
        white-space: pre;
        font-weight: 700;
        color: var(--yelow-color);
        background: #474649;
        transition: box-shadow 0.2s;
    }

    .p-datatable-tbody>tr {
        background: #474649;
        color: #7b7b7b;
        transition: box-shadow 0.2s;
        outline-color: #c7d2fe;
    }
}

.p-datatable-thead>tr>th {
    background-color: white !important;
    font-weight: 700 !important;
}

.p-datatable .p-datatable-tbody>tr {
    color: #7b7b7b !important;
    font-size: 12px;
}

.p-paginator {
    background: #474649;
    border: solid #e9ecef;
    border-width: 0;
    padding: 0.5rem 1rem;
    border-radius: 6px;

    .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        background-color: transparent;
        border: 0 none;
        color: #007c84;
        min-width: 3rem;
        height: 3rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 50%;
    }

    .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        background-color: transparent;
        border: 0 none;
        color: #007c84;
        min-width: 3rem;
        height: 3rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 50%;
    }

    .p-paginator-next,
    .p-paginator .p-paginator-last {
        background-color: transparent;
        border: 0 none;
        color: #007c84;
        min-width: 3rem;
        height: 3rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 50%;
    }

    .p-paginator-last {
        background-color: transparent;
        border: 0 none;
        color: #007c84;
        min-width: 3rem;
        height: 3rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 50%;
    }
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    margin-top: -0.5rem;
}

.innr-Body {
    max-height: 540px;
    overflow: auto;
    // padding-top: 0;

    /* width */
    &::-webkit-scrollbar {
        width: 10px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #474649 !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #f99506 !important;
        border-radius: 15px !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #f99506 !important;
    }
}

.p-inputtext {
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    color: var(--bluegray-50);
    // background: transparent;
    background-color: rgb(14 130 138 / 5%) !important;
    padding: 0.75rem 0.75rem;
    // border: 1px solid var(--yelow-color);
    // border: none;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;

    &:enabled:hover {
        border-color: var(--yelow-color);
    }
}

.p-dialog {
    .p-dialog-header {
        border-bottom: 0 none;
        background-color: $bgDarkColor;
        color: white;
        padding: 1.5rem;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        .p-dialog-header-icon {
            color: #343a40;
            border-color: transparent;
            background: #d97706;

            .p-dialog-header-close-icon {
                color: #ffffff;
            }

            &:enabled:hover {
                color: #343a40;
                border-color: transparent;
                background: #d97706;
            }
        }
    }

    .p-dialog-content {
        background-color: $bgDarkColor;
        color: white;
        padding: 0 1.5rem 2rem 2.5rem;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}

.text-2xl {
    font-size: 1rem !important;
    margin-bottom: 5px;
    color: #ffffff;
}

.dropdown-text {
    // background-color: transparent;
    background-color: #474649;
    // border-color: #d97706;
    border: none;
    margin-top: 5px;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #f8a91b;
}

.p-dialog {
    &::-webkit-scrollbar-thumb {
        background: #f99506 !important;
        border-radius: 15px !important;
    }
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0.75rem 0;
    background: #222733;

    .p-dropdown-item {
        margin: 0;
        padding: 0.75rem 1.25rem;
        border: 0 none;
        color: #ffffff;
        background: transparent;
        transition: box-shadow 0.2s;
        border-radius: 0;
    }

    .p-dropdown-empty-message {
        padding: 0.75rem 1.25rem;
        color: black;
        background: transparent;
    }
}

.p-dropdown-items-wrapper {
    overflow: auto;
    background: #474649;
}

.p-sidebar {
    background-color: $bgDarkColor;

    .p-sidebar-header {
        padding: 0rem 1.25rem;
    }

    .p-sidebar-content {
        padding: 0rem 3.25rem;
    }

    .p-sidebar-header .p-sidebar-close,
    .p-sidebar .p-sidebar-header .p-sidebar-icon {
        width: 2rem;
        height: 2rem;
        color: #ffffff;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }
}

.border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
    font-weight: 600;
    color: #ffffff;
}

.Add__New-Button {
    padding: 12px 30px !important;
    font-size: 0.81rem !important;
    border-radius: 4rem !important;
    background: #007c84 !important;
    border: none !important;
    cursor: pointer;
    margin-top: 0;
}

.Clear__New-Button {
    padding: 12px 30px !important;
    font-size: 0.81rem !important;
    border-radius: 4rem !important;
    background: #dc3545 !important;
    border: none !important;
    cursor: pointer;
    margin-top: 0 !important;
}

.text-4xl {
    font-size: 1.5rem !important;
}

.Salary__Btn {
    font-size: 1.5rem !important;
    padding: 0.5rem 1rem !important;
    white-space: pre !important;
}

.dropdown-btn {
    margin-top: 1.5rem !important;

    .button-grey {
        background-color: #474649 !important;
        color: #f99506 !important;
        border: none !important;
        border-radius: 25px;
    }

    .button-orange {
        background-color: #f99506;
        color: #ffffff;
        border: none !important;
        border-radius: 25px;
    }
}

.input-round {
    border: none !important;
}

.Label__Required {
    color: #ff6c6c;
    padding-left: 5px;
}

.p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #ffffff;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #000000;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 50%;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
    color: #ffffff;
    font-weight: 500;
}

.p-multiselect-panel .p-multiselect-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #dee2e6;
    color: #343a40;
    background: #474649;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border: 0 none;
    color: #fff;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.p-multiselect-items-wrapper {
    overflow: auto;
    background: #474649;
    color: #fff;
}

.p-multiselect-label {
    white-space: normal;
}

.p-multiselect-token {
    margin: 2px;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
    // height: 35px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.p-datatable .p-column-header-content {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

// setting Icon css
.layout-config .layout-config-button {
    display: none;
}

.remember-forget {
    color: var(--yelow-color);
}

.p-dialog .p-dialog-footer {
    background: #38373d;
}

.p-inline-message.p-inline-message-error {
    background: none;
}

.p-inline-message.p-inline-message-success {
    background: none;
}

.red {
    background-color: red;
}

.proj-alert {
    padding: 15px 0;
    background-color: #191c24;
    border-radius: 12px;
}

.card-bar {
    width: 100px;
    justify-content: flex-end;
}

.bg-1 {
    background: linear-gradient(83deg, #736dff, #a97fff);
}

.bg-2 {
    background: linear-gradient(83deg, #44dec5, #4ebcfa);
}

.bg-3 {
    background: linear-gradient(83deg, #deb544, #d5c896);
}

.bg-4 {
    background: linear-gradient(83deg, #f7c598, #ff8886);
}

.bg-5 {
    background: linear-gradient(83deg, #f9b4bd, #9573db);
}

.p-datatable .p-datatable-thead>tr>th {
    font-size: 14px;
    color: #f8a91b !important;
    font-weight: 400 !important;
}

.card .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody .p-datatable-emptymessage td {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}

.card .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody .p-datatable-emptymessage td span i {
    font-size: 16px;
}

.p-paginator {
    background: #222733;
}

.p-datatable .p-datatable-thead>tr>th {
    background: transparent;
}

.dark-bg {
    background-color: white !important;
    border-radius: 10px;
    box-shadow: 12px 9px 22px -8px #d4d4d4 !important;
}

.layout-menu li a {
    color: #007c84 !important;
}

.layout-menu li a.router-link-exact-active {
    font-weight: 700;
    background-color: white !important;
    color: red !important;
    margin-top: 0.71rem;
}

.p-sidebar .p-sidebar-header .p-sidebar-close {
    background: #007c84 !important;
}

.p-datatable .p-datatable-tbody>tr {
    background: transparent;
}

.layout-menu li .layout-menuitem-root-text {
    text-align: center;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #000000;
    color: #ffffff;
}

.p-paginator .p-dropdown {
    background: #191c24;
    border: none;
}

.p-paginator .p-dropdown .p-dropdown-label {
    color: #ffffff;
}

.p-paginator {
    border: solid rgba(222, 226, 230, 0.1019607843);
}

.p-button {
    background: black;
    border: 1px solid black;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
    background: black !important;
    color: #ffffff;
    border-color: black !important;
}

button:not(button):not(a):not(.p-disabled):hover {
    background: black !important;
    color: #ffffff;
    border-color: #007c84 !important;
}

.p-button:enabled:active,
.p-button:not(button):not(a):not(.p-disabled):active {
    background: black !important;
    color: #ffffff;
    border-color: black !important;
}

//Tab View Styling
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #007c84 !important;
    color: #007c84 !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #007c84 !important;
    color: #007c84 !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #007c84 !important;
}

.p-datatable .p-datatable-tbody>tr>td {
    text-align: center;
    border: 0;
    border-bottom: 1px !important;
    // padding: 1.5rem !important;
    outline-color: #c7d2fe !important;
}

.stat-body .progress-card-bar {
    width: 90px;
    height: 130px;
    margin-right: 10px;
    background: #191c24;
    padding: 10px;
    border-radius: 6px;
}

.flex-class {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
}

.stat-body .progress-card-bar small {
    height: 30px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-inputtext {
    border-radius: 6px;
}

.layout-menu li a {
    border-radius: 6px;
    color: white !important;
}

.flex-class h2 {
    margin: 0;
}

.p-dropdown {
    border: 1px solid #828282;
    background: transparent;
    border-radius: 12px;
}

.p-dropdown:hover {
    border: 1px solid #828282 !important;
}

.p-dropdown:focus {
    outline: none !important;
    border: 1px solid #828282 !important;
}

.p-inputtext {
    background-color: rgb(71 70 73 / 10%);
    border: 1px solid rgb(187 187 187 / 24%);
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #f7f8f9;
}

.p-dropdown:focus-visible {
    outline: none;
}

.highlight-text {
    background-color: yellow;
    /* You can use any highlighting style you prefer */
    font-weight: bold;
    /* To make the text bold, for example */
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    border-color: #f8a91b;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #f8a91b;
}

.p-inputtext:enabled:hover {
    border-color: #f8a91b;
}

.risk-slider div {
    margin-top: 15px;
}

.p-slider .p-slider-range {
    background: #f8a91b;
}

.p-slider .p-slider-handle {
    border: 2px solid #f8a91b;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #f8a91b;
    border-color: #f8a91b;
}

.p-divider .p-divider-content b {
    background: #1d212b;
    padding: 0px 10px;
    font-size: 1.7rem;
}

.p-tag {
    background: #1d212b;
    padding: 0px 10px;
    font-size: 1.25rem;
}

.p-divider .p-divider-content {
    background-color: transparent;
}

.p-divider.p-divider-horizontal:before {
    border-top: 1px #dee2e626;
    border-top-style: solid;
}

.modal-btns {
    display: flex;
    align-items: center;
    justify-content: end;
}

.save-btn {
    width: 125px !important;
}

.cancel-btn {
    background: #dc2626;
    border: 1px solid #ff6c6c;
}

.cancel-btn:enabled:hover,
.cancel-btn:not(button):not(a):not(.p-disabled):hover {
    background: #dc2626;
    color: #ffffff;
    border-color: #dc2626;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd {
    background: #1d212b;
}

.edit-btn {
    color: #008d58;
    border: none !important;
    background: none !important;
}

.edit-btn:enabled:hover {
    background: none !important;
    color: #ffffff;
    border-color: #007c84 !important;
}

.Cancel-Button:enabled:hover {
    // color: #DC2626;

    background: none !important;
}

.Save-Button:enabled:hover {
    // color: #DC2626;
    border: none !important;
    background: #000000 !important;
}

.delete-btn:enabled:hover {
    color: #dc2626;
    border: none !important;
    background: none !important;
}

.delete-btn {
    color: #dc2626;
    border: none !important;
    background: none !important;
}

// .delete-btn:hover {
//     background: #DC2626 !important;
//     color: white !important;
//     border: none !important;
// }

.add-btn {
    color: #3b82f6 !important;
    border: none !important;
    background: none !important;
}

// .add-btn:hover {
//     color: white !important;
//     border: none !important;
//     background: #3B82F6 !important;
// }

/* Add this style to your CSS file */
.custom-dashboard-icon {
    background-image: url("../src/assets/jswall_assets/JS WALL/colored/dashboard.png");
    background-size: cover;
    width: 16px;
    height: 16px;
}

.custom-users-icon {
    background-image: url("../src/assets/jswall_assets/JS WALL/colored/user_management.png");
    background-size: cover;
    width: 15px;
    height: 15px;
}

.p-inputtext-sm::placeholder {
    color: rgba(0, 0, 0, 0.242) !important;
}

.custom-incident-icon {
    background-image: url("../src/assets/jswall_assets/JS WALL/colored/incident_management.png");
    background-size: cover;
    width: 15px;
    height: 15px;
}

.custom-inquiry-icon {
    background-image: url("../src/assets/jswall_assets/JS WALL/colored/Inquiry_management.png");
    background-size: cover;
    width: 15px;
    height: 15px;
}

.custom-roles-icon {
    background-image: url("../src/assets/jswall_assets/JS WALL/colored/rolesnrightsmgmt_g.png");
    background-size: cover;
    width: 18px;
    height: 15px;
}

.eye-btn {
    font-size: 12px !important;
    color: rgb(79, 79, 243) !important;
    border: none !important;
    background: none !important;
}

// .eye-btn:hover {
//     color: white !important;
//     border: none !important;
//     background: var(--yelow-color) !important;
// }

.p-divider.p-divider-horizontal {
    margin: 25px 0 30px;
}

.p-dialog .p-dialog-content {
    padding: 2rem 2.5rem 2rem 2.5rem;
    background-color: #1d212b;
}

.p-dialog .p-dialog-header {
    background-color: #2d3037;
}

// .css-df17o1 {
//     height: 125% !important;
//     width: 107.9% !important;
//     top: -27px !important;
//     left: -35px !important;
//     background: rgb(38 41 51 / 78%) !important;
// }
.p-checkbox {
    width: 35px;
    height: 35px;
}

.p-checkbox .p-checkbox-box {
    width: 20px;
    height: 20px;
    margin-top: 6px;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none !important;
}

.text-h5 h5 {
    color: #f8a91b;
}

.bg-none {
    background-color: transparent !important;
    background: transparent !important;
    box-shadow: none !important;
}

.topbar-heading {
    font-size: 18px;
    color: #007c84;
    font-weight: 700;
}

.custom-button-css>.p-button {
    color: #007c84 !important;
}

.p-badge.p-badge-warning {
    background-color: #007c84 !important;
}

.p-paginator .p-paginator-current {
    color: #000000 !important;
}

.p-paginator {
    color: #000000 !important;
    background: transparent !important;
}

.p-paginator .p-dropdown {
    background: #000000 !important;
}

.p-dropdown .p-dropdown-trigger {
    color: #bababa !important;
}

.p-datatable .p-datatable-thead>tr>th {
    font-size: 14px;
    font-weight: 600 !important;
    color: red !important;
}

.layout-login {
    height: 100vh;
}

.layout-topbar {
    background-color: black !important;
}

.layout-theme-light {
    background-color: #007c840d !important;
}

.p-divider .p-divider-content {
    color: #007c84;
}

.p-datatable .p-datatable-header {
    border: none !important;
}

.layout-topbar .layout-topbar-logo {
    font-size: 1.3rem;
}

.layout-topbar .layout-menu-button {
    margin-left: -3rem;
}

.layout-topbar .layout-topbar-button {
    color: #007c84 !important;
}

.layout-topbar .layout-topbar-button:hover {
    color: #007c84 !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd {
    background: none !important;
}

.p-datatable .p-datatable-tbody>tr>td {
    font-size: 13.5px !important;
}

// .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
//     background: #007c84;
//     color: #ffffff;
// }

// FILE UPLOAD FIELD

.fileUploadBtn {
    border: 1px solid #ced4da;
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    padding: 4px !important;
}

input[type="file"]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #b77b15;
    padding: 8px 15px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
    background: #915e07;
}

.p-invalid.fileUploadBtn {
    border-color: #e24c4c;
}

.icon-field small {
    position: absolute;
    margin-top: 60px;
}

.border-radius-12px {
    border-radius: 12px !important;
}

.p-multiselect {
    border: 1px solid #828282;
    background: transparent;
    border-radius: 12px;

    // background: rgb(33 37 46) !important;
    // border: 1px solid rgba(187, 187, 187, 0.24) !important;
    // transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    // border-radius: 6px;
    // height: 35.5px;
    &:hover {
        border-color: #f8a91b !important;
    }

    .p-multiselect-label-container {
        color: gray;
    }

    .p-multiselect-clear-icon {
        cursor: pointer;
    }
}

.p-multiselect-panel {
    max-width: min-content;
}

//Login stylings
.surface-card {
    background-color: #191c24;
}

.login-div .p-password input {
    width: 100%;
}

//Logout split button
.custom-button-css>.p-button {
    color: white;
    background: none !important;
    border: none !important;
    margin-left: -10px !important;
    font-size: 1.2rem !important;
    width: 3.2rem !important;
    height: 3.2rem !important;
    border-radius: 50% !important;
}

.custom-button-css>.p-button:hover {
    color: gray !important;
    border: none !important;
    border-radius: 50% !important;
}

.custom-button-css>.p-splitbutton-defaultbutton {
    display: none !important;
}

//Dialog
.p-dialog .p-dialog-content {
    background-color: #f3f8f9 !important;
    color: #007c84;
}

.p-dialog .p-dialog-header {
    background-color: #f3f8f9 !important;
    color: #007c84 !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-top: 30px;
}

.p-inputtext-sm {
    color: gray !important;
    border-radius: 10px !important;
    background-color: rgb(14 130 138 / 5%) !important;
}

.p-inputtext-login {
    color: gray !important;
    // padding: 12px 16px !important;
    border-radius: 7px !important;

    background-color: transparent !important;
}

.custom-password-field {
    color: gray !important;
    border-radius: 7px !important;
    background-color: #007c8403 !important;
}

.search-input {
    background: transparent !important;
}

label {
    color: black !important;
    font-weight: 700 !important;
}

.login-div label {
    color: white !important;
}

.p-inputtext {
    color: gray !important;
    border: 1px solid gray;
    border-radius: 12px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    background: #f3f8f9 !important;
    color: gray !important;
}

.p-dropdown-panel .p-dropdown-items {
    background: #f3f8f9 !important;
    color: black !important;
}

.p-paginator .p-dropdown .p-dropdown-label {
    color: #e4eef2 !important;
}

// .p-paginator .p-paginator-pages .p-paginator-page {
//     background-color: #007c84 !important;
//     color: #fff;
// }

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: #e9ecef !important;
}

.p-button-label {
    font-size: 0.86rem !important;
}

.Save-Button {
    width: 20% !important;
    padding: 12px !important;
    border-radius: 1rem !important;
    background: #000000 !important;
    color: white;
    border: none !important;
    cursor: pointer;
    margin-top: 0 !important;
}

.Save-Button-Profile {
    width: 22% !important;
    padding: 12px !important;
    border-radius: 1rem !important;
    background: #000000 !important;
    color: white;
    border: none !important;
    cursor: pointer;
}

.Save-Button-Preview {
    width: 45% !important;
    margin-top: 12px;
    padding: 12px !important;
    border-radius: 1rem !important;
    background: #f99506 !important;
    color: white;
    border: none !important;
    cursor: pointer;
}

.Add-Incident-Button {
    width: 23% !important;
    padding: 13px 18px !important;
    font-size: 0.81rem !important;
    border-radius: 4rem !important;
    background: #007c84 !important;
    border: none !important;
    cursor: pointer;
    margin-top: 0 !important;
}

.Login-Button {
    width: 100% !important;
    padding: 14px 24px !important;
    font-size: 1rem !important;
    border-radius: 5px !important;
    background: #ff0000 !important;
    border: none !important;
    cursor: pointer;
    margin-top: 0 !important;
}

.Cancel-Button {
    width: 20% !important;
    padding: 10px !important;
    border-radius: 1rem !important;
    background-color: white !important;
    color: #0c213a !important;
    border: 1px solid #0c213a !important;
    cursor: pointer;
    // margin-top: 0 !important;
}

.Preview-Video {
    background-color: red;
    border-radius: 1rem !important;
    border: 1px solid red !important;
}

.Preview-Video:enabled:hover {
    // color: #DC2626;

    background: red !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    background: #007c84 !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    background-color: black !important;
    color: white;
}

.p-multiselect-items-wrapper {
    background-color: #f3f8f9 !important;
    color: gray !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    color: gray !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: #007c84 !important;
    border-color: #007c84 !important;
}

.p-multiselect-panel .p-multiselect-header {
    background: #f3f8f9 !important;
    color: gray !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: #007c84 !important;
    border-color: #007c84 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: black !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background-color: #007c84 !important;
    border-color: #007c84 !important;
}

.p-dialog .p-dialog-footer {
    background: #f3f8f9;
}

button.p-button.p-component.p-confirm-dialog-accept {
    background: #007c84 !important;
    border-color: #007c84 !important;
    color: white !important;
}

//Dashboard Hover effect
.overlay {
    cursor: pointer;
    position: absolute;
    top: 8%;
    left: 9%;
    width: 80%;
    min-height: 85%;
    background: rgba(50, 50, 50, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    visibility: hidden;
    opacity: 0;
    border-radius: 16px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.6);
    transform: translateY(-10%);
    transition: visibility 0s, opacity 0.3s, transform 0.3s ease-in-out;
}

.overlay:hover {
    opacity: 1;
    transform: translateY(0);
}

.overlay a {
    text-decoration: underline;
}

.overlay h5 {
    font-size: 18px;
    margin: 12px 0;
}

.overlay div {
    font-size: 14px;
    margin: 4px 0;
}

.img-container:hover .overlay {
    visibility: visible;
    opacity: 1;
}

/* Reduce opacity when hovering */
.img-container:hover .hover-opacity {
    opacity: 0.2;
}

.p-datatable .p-datatable-tbody>tr {
    background: transparent;
    border-bottom: 1px solid #e3edf2 !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    display: none;
}

.layout-sidebar {
    top: 6rem;
    background-color: black !important;
}

// Login page

.loginBgContainer {
    position: relative;

    height: 100vh;
}

.loginBgImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
}

.text {
    text-align: center;
    display: flex;
    padding-top: 170px;
    // height: 0vh;

    justify-content: center;
}

.pageNotFound {
    width: 50%;
    // height: 40%;
    // top: 0;
    // right: 0;
    // z-index: -1;
    // background-repeat: no-repeat;
    // background-position: center;
}

.left-section {
    position: relative;
    /* Ensure child elements are positioned relative to this container */
    z-index: 1;
    /* Ensure the form appears above the background image */
    padding: 20px;
    /* Adjust as needed */
}

.imgAppLogo {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100vw;
    max-height: 100%;
    object-fit: contain;
    z-index: 1;
}

// .right-section {
//     flex: 1;
//     width: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

.login-form {
    margin-top: 100px;
    margin-left: 150px;
    width: 35vw;
    padding: 7rem;
    border: 1px solid red;
    border-radius: 20px;
}

.loginField {
    background-color: transparent !important;
}

.Main__Header {
    color: black;
}

.Btn__Add {
    width: 15% !important;
    float: right;
    box-shadow: 5px 7px 22px -8px #6c6c6c !important;
}

.Btn_Upload {
    width: 140px !important;
    float: right;
    margin: 10px 20px;
}

.Btn__Back {
    width: 15% !important;
    float: right;
    margin-right: 10px;
    box-shadow: 5px 7px 22px -8px #6c6c6c !important;
}

.Add_Btn {
    background-color: black;
}

.p-dialog .p-dialog-content {
    background-color: white !important;
    color: #007c84;
}

.p-dialog .p-dialog-header {
    background-color: white !important;
    color: black !important;
}

.p-datatable .p-datatable-thead>tr>th {
    background-color: transparent !important;
    color: #be0101 !important;
    font-weight: 700 !important;
    border-bottom: 1px solid #ccc !important;
}

.custom-datatable {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.custom-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    color: #333;
}

.custom-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 10px;
    /* Adjust the padding as needed */
}

.custom-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}

.query-table-main {
    // width: 55%;
    // padding: 20px;
    // border-right: 1px #ccc;
    // border-style: dashed;
}

.queryRow {
    background: transparent;
    border-bottom: 1px solid #e3edf2 !important;
    height: auto;
    display: flex;
    justify-content: space-between;
    color: black;
    align-items: center;

    margin: 20px 80px 20px 0px;
    padding-bottom: 20px;
}

.queryRow p {
    color: black;
    display: flex;
    text-align: justify;
    justify-content: flex-end;
    width: 50%;
}

.queryRow h6 {
    margin: 0px;
}

.Label__Text {
    color: black !important;
    font-weight: 700;
}

.Add_Btn {
    margin-left: 20px;
}

.card {
    margin-left: 10px !important;
    // height: 100px !important;
    background-color: lightgray;
    border-radius: 0%;
}

.heading-system {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: black;
    font-weight: 700;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it's on top of other elements */
}

.modal {
    background: white;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    z-index: 1001;
    /* Ensure it's above the overlay */
}

.modal-content {
    margin-bottom: 20px;
}

.Btnn__Add__ {
    // padding: 35px 80px;
    // margin: 35px;
    // margin-right: 80px;
    // height: 80px;
    border-radius: 10px;
    background: lightgray;
    color: black;
    border: none;
    font-size: 13px;
    font-weight: 500;
    box-shadow: none;
    white-space: pre-wrap;
    min-width: 150px;
}

.main-card {
    border-radius: 10px;
}

.p-inputtext {
    background-color: transparent !important;
}

.p-inputtext:focus {
    outline: none !important;
}

.p-inputtext-sm {
    background-color: white !important;
}

.p-paginator .p-paginator-current {
    color: black !important;
}

// .p-paginator .p-paginator-pages .p-paginator-page {
//     background-color: black !important;
// }
.p-dialog .p-dialog-header {
    text-align: center;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    background-color: white !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon .p-dialog-header-close-icon {
    color: black !important;
}

.layout-menu li a:hover {
    color: red !important;
}

.remarksSection {
    text-align: center !important;
    // margin-top: 50px;
}

.remarksSection h3 {
    color: black;
}

.remarksSection textarea {
    width: 80%;
    border-radius: 1rem;
    padding: 20px;
    font-size: 16px;
}

.remarksSection textarea:focus {
    outline: none;
}

.carImage {
    padding: 20px;
    margin-top: 30px;
}

.carImage img {
    width: 80%;
}

.carDetails {
    color: black;
    text-align: center;
}

.carDetails p {
    margin-bottom: 0px !important;
}

.carDetails h5 {
    margin: 6px !important;
    color: red !important;
    font-weight: 800 !important;
}

.carDetails h2 {
    margin: 6px !important;
}

.carDescription {
    color: #828282;
    margin-top: 20px;
    border-top: 3px dotted #ccc;
}

.img-icon {
    // width: 8.5rem;
    height: 5.5rem;
}

.vehicle {
    background-color: #582b90 !important;
}

.inactive-div {
    background-color: #dc952a !important;
}

.active-div {
    background-color: #252c58 !important;
}

.inventory-div {
    background-color: #be0101 !important;
}

.totalparts-div {
    background-color: #705d5d !important;
}

.totalcars-div {
    background-color: #483444 !important;
}

.systemsettings {
    background-color: #b1afaf !important;
    padding: 25px;
}

// .system-setting-btn{
//     background-color: transparent !important;
//     color: black;
//     border: none !important;

// }
// .system-setting-btn:focus{
//     outline: none;
//     box-shadow: none;
//     border: none;
// }

// .system-setting-btn:hover{
//     background-color: #b1afaf !important;
//     color: black !important;
// }

.stats-text div {
    color: white;
    // font-size: 35px !important;
}

.crossicon {
    float: right;
    width: 10px;
    cursor: pointer;
}

.choose {
    margin-top: 10px;
}

.redBorder {
    border-bottom: 2px solid red;
}

.otp-container {
    display: flex;
    justify-content: center;
}

.otp-box {
    width: 50px;
    /* Adjust size as needed */
    height: 50px;
    /* Adjust size as needed */
    font-size: 1.5rem;
    background-color: none;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    /* Adjust spacing between boxes */
}

.updatePasswordButtons {
    button {
        width: 160px !important;
    }
}

.radio-buttons {
    font-size: 20px;
}

.dateStats {
    margin-left: 50px;
    margin-right: 10px;
}

.radio-buttons label {
    margin-right: 50px;
}

@media (max-width: 1920px) and (min-width: 1441px) {
    .Btn__Add {
        width: 25% !important;
        float: right;
    }
}

@media (max-width: 1440px) and (min-width: 1200px) {
    .Btn__Add {
        width: 30% !important;
        float: right;
    }

    // .Btnn__Add__ {
    //     padding: 35px;
    //     margin: 35px;
    //     margin-right: 25px;
    //     height: 75px;
    // }

    .radio-buttons {
        font-size: 14px !important;
    }

    .dateStats {
        margin-left: 40px !important;
        margin-right: 10px !important;
    }

    .radio-buttons label {
        margin-right: 10px !important;
    }
}

/* Media query for screens 1000px and 760px or less */
@media (max-width: 1100px) {
    .Login-Button {
        width: 40% !important;
    }

    .Save-Button {
        width: 19% !important;
    }

    .Cancel-Button {
        width: 22% !important;
    }
}

@media (max-width: 1024px) {
    .layout-sidebar {
        top: 6rem !important;
    }

    .layout-wrapper.layout-static .layout-main-container {
        //  margin-left: 210px;
    }

    .Btn__Add {
        width: 35% !important;
        float: right;
    }

    .Btnn__Add__ {
        padding: 20px;
        // margin: 15px;
        height: 80px;
    }

    .radio-buttons {
        font-size: 14px !important;
    }

    .dateStats {
        margin-left: 40px !important;
        margin-right: 10px !important;
    }

    .radio-buttons label {
        margin-right: 10px !important;
    }
}

@media (max-width: 880px) {
    .left-section {
        display: block;
    }

    .login-form {
        margin-top: 100px !important;
        margin-left: 50px !important;

        padding: 2rem !important;
        border: 1px solid red;
        border-radius: 20px;
    }

    .right-section {
        flex: 1;
    }

    .login-form {
        width: 55vw !important;
    }

    .Login-Button {
        width: 100% !important;
    }

    .Cancel-Button {
        width: 47% !important;
    }

    .Save-Button {
        width: 39% !important;
    }
}

@media (max-width: 767px) {
    .left-section {
        display: block;
    }

    .login-form {
        margin-top: 100px !important;
        margin-left: 5px !important;
        padding: 1rem !important;
        border: 1px solid red;
        border-radius: 20px;
    }

    .right-section {
        flex: 1;
    }

    .login-form {
        width: 84vw !important;
    }

    .Login-Button {
        width: 100% !important;
    }

    .Cancel-Button {
        width: 47% !important;
    }

    .Save-Button {
        width: 39% !important;
    }
}

@media (max-width: 550px) {
    .d-column {
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-self: start !important;
        align-items: flex-start !important;
    }

    .flexColumn {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        text-align: center !important;
        align-items: center !important;
    }

    .abc {
        margin-bottom: 4px !important;
    }
}

@media (max-width: 530px) {
    .custom-alignment {
        display: inline !important;
    }
}

@media (max-width: 480px) {
    .d-None {
        display: none !important;
    }

    .import-export-btn {
        display: flex !important;
    }

    .Cancel-Button {
        width: 70% !important;
    }

    .Save-Button {
        width: 70% !important;
        margin-right: 4px !important;
    }
}

.important-black {
    color: black !important;
}

.file-input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.file-input-field {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
}

.file-upload-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}


//Custom Spinner Loader
.custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: blinkBackground 1s infinite;
    z-index: 9999;
}

@keyframes blinkBackground {

    0%,
    100% {
        background: rgba(110, 110, 110, 0.4);
    }

    50% {
        background: rgba(110, 110, 110, 0.2);
    }
}

.custom-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.custom-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #be0101;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* DotLoader.css */
.dot-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure the loader is on top */
}

.dot-container {
    display: flex;
    align-items: center;
}

.dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 5px;
    animation: dotAnimation 1.5s infinite ease-in-out;
}

.red {
    background-color: #ff5733;
    /* Red */
}

.orange {
    background-color: #f39c12;
    /* Orange */
}

.dot:nth-child(2) {
    animation-delay: 0.5s;
}

.dot:nth-child(3) {
    animation-delay: 1s;
}

@keyframes dotAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.desc_Text_Area {
    width: 97%;
}

.desc_Text_Area textarea {
    resize: none;
    height: 70px;
}

.custom-file {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 0.75rem 0.75rem !important;
    font-size: 0.81rem;
    line-height: 1.6;
    color: black;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 12px;
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    border: 1px solid grey;
    box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;
}

.fixed-height {
    height: 50px;
    /* Or any fixed height you prefer */
    display: flex;
    align-items: center;
    /* Ensure items are centered vertically within the fixed height */
    flex-shrink: 0;
    /* Prevent shrinking */
    flex-grow: 0;
    /* Prevent growing */
}

.title-border {
    padding: 5px 15px;
    border-bottom: 3px solid red;
}

.replyHistorySection.card {
    height: auto !important;
}

.text-wrap {
    white-space: normal;
    word-break: break-word;
}

.custom-addon {
    .p-inputtext {
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
    }

    .p-inputgroup-addon {
        padding: 0;
        background: transparent;
        border: 1px solid #828282;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;

        .p-dropdown {
            border: 0;

            &:hover {
                border: 0 !important;
            }
        }
    }
}

.card-content {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    max-height: 800px;
}

.content {
    flex: 1;
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.chat-container {
    padding: 10px;
}

// .chat-bubble {
//     margin: 10px 0;
//     padding: 10px;
//     border-radius: 10px;
//     max-width: 70%;
//     position: relative;
// }

.customer {
    background-color: #bcb7b7;
    align-self: flex-start;
}

.my-reply {
    background-color: #0084ff;
    color: white;
    align-self: flex-end;
}

.timestamp {
    font-size: 0.8em;
    color: #888;
    position: absolute;
    bottom: -20px;
    right: 10px;
}

.reply-history {
    .chat-container {
        .chat-bubble {
            margin: 10px 0;
            position: relative;
            overflow-wrap: break-word;
            /* Ensures long words break */
            word-wrap: break-word;
            /* Supports older browsers */
            word-break: break-word;

            .customer {
                max-width: 48%;
                border-radius: 10px;
                border-radius: 10px;
                padding: 10px;
                width: fit-content;
            }

            .my-reply {
                max-width: 48%;
                border-radius: 10px;
                border-radius: 10px;
                padding: 10px;
                width: fit-content;
                margin-left: auto;
            }
        }
    }
}

.status-all {
    font-weight: bold;
    color: blue;
}

.status-active {
    font-weight: bold;
    color: green;
}

.status-inactive {
    font-weight: bold;
    color: red;
}

.status-delivered {
    font-weight: bold;
    color: rgb(239, 113, 113);
}

.status-pending {
    font-weight: bold;
    color: rgb(255, 187, 0);
}

.status-approved {
    font-weight: bold;
    color: rgb(0, 255, 68);
}

.status-processed {
    font-weight: bold;
    color: rgb(206, 151, 0);
}

.layout-menuitem-category {
    li {
        a {
            &.router-link-active {
                sup {
                    background: white;
                }
            }

            &:hover {
                sup {
                    background: white;
                }
            }

            sup {
                border: 2px solid;
                border-radius: 50%;
                padding: 5px;
                background: black;
                color: red;
                font-weight: bold;
            }
        }
    }
}

.text-black {
    color: #000000;
}

.customPaginator {
    .paginatorPages {
        border-radius: 100%;
        height: 30px;
        width: 30px;
        padding: 0;
        color: #000000;
    }
}

.zindex9999 {
    z-index: 1200 !important;
}

.zindex8888 {
    z-index: 1100 !important;
}

.layout-menuitem-category li a sup {
    padding: 5px 8px !important;
}

.box-shadow {
    box-shadow: 10px 14px 22px -8px #cccccc !important;
}

.box-shadow:hover {
    box-shadow: none !important;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
}

.input-field {
    flex: 1;
    padding-right: 40px;
    border-radius: 12px;
}

.search-button {
    position: absolute !important;
    right: 4px !important;
    top: 50% !important;
    transform: translateY(-50%);
    border: none !important;
    background: black !important;
    color: white !important;
    padding: 10px 14px;
    border-radius: 10px;
    cursor: pointer;
}

.card-shadow {
    box-shadow: 5px 7px 22px -8px #6c6c6c;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: black !important;
    border-color: black !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: black !important;
    border-color: black !important;
}

.custom-alignment {
    display: flex;
    align-items: center;
}

// Reporting
.reporting-card-gradient {
    background: linear-gradient(119deg, rgb(255, 255, 255) 15%, rgb(172 172 172 / 39%) 99%) !important;
    box-shadow: 6px 8px 12px 4px rgb(222, 222, 222) !important;
    transition: transform 0.8s ease;
    color: #000000;
    padding: 34px 10px !important;
    border-radius: 14px;
}

.reporting-card-gradient:hover {
    cursor: pointer;
    transform: scale(0.96);
}

.reporting-header {
    font-weight: 600;
    font-size: 14px;
}

.reporting-card-flex {
    display: flex;
    align-items: center;
}

.reporting-card-flex img {
    width: 40px;
    height: 40px;
}

.header-color {
    color: #be0101 !important;
}

.Add__New-Button {
    padding: 12px 30px !important;
    font-size: 0.9rem !important;
    border-radius: 10px !important;
    border: none !important;
    cursor: pointer;
    margin-top: 0;
}

.export-excel {
    background: #4BB543 !important;
}

.query-btn {
    background: black !important;
}

.export-excel:enabled:hover {
    background: #338e2c !important;
}

.export-pdf {
    background: #d9534f !important;
}

.export-pdf:enabled:hover {
    background: #db3631 !important;
}

.p-multiselect-token-label {
    font-size: 12px !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    font-size: 12px !important;
}

.topbar-image {
    margin-left: 60%;
}

@media screen and (max-width: 770px) {
    .p-dialog {
        width: 80vw !important;
    }

    .resp-column {
        display: flex !important;
        flex-direction: column !important;
    }

    .custom-alignment {
        align-items: flex-end !important;
    }

    .resp-status {
        display: none !important;
    }

    .query-resp {
        flex-direction: column !important;
    }

    .topbar-image {
        margin-left: 20%;
    }

}

@media screen and (max-width: 500px) {
    .p-dialog {
        width: 90vw !important;
    }

    .resp-column-paginator {
        display: flex !important;
        flex-direction: column !important;
    }
}