
.modal {
    width: 35%;
    min-height: 200px;
}
 
.modal-overlay {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    color: black;
}
 
.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
 
.mainModalImage img {
    height: 100px;
    width: 100px;
    object-fit: contain;
}
 
.modal-buttons {
    display: flex;
}
 
 
 
.modal-left-button {
    height: 40px;
    width: 70px;
    background-color: white;
    color: white;
    border: 1px solid black;
    border-radius: 20px;
}
 
.modal-right-button {
    height: 40px;
    width: 70px;
    background-color: white;
    color: white;
    border: 1px solid black;
    border-radius: 20px;
}
 
.redCircle img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    outline: none;
 
 
}
 
.redCircle {
    background-color: #c41c1c;
    ;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-top: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
 
.heading {
    font-weight: bold;
    margin-top: revert;
}