* {
    box-sizing: border-box;
}

*:focus {
    box-shadow: none !important;
}

.p-sidebar-right .p-sidebar {
    width: 50%;
}

.w-95 {
    width: 95% !important;
}

html {
    height: 100%;
    // font-size: $fontSize;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--surface-ground);
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
button {
    text-decoration: none;
    color: var(--primary-color);
}

.layout-theme-light {
    background-color: #222733;
}