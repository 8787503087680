.layout-sidebar {
    position: fixed;
    width: 300px;
    // height: calc(115vh - 9rem);
    height: calc(100vh - 0rem);
    z-index: 999;
    overflow-y: auto;
    -webkit-user-select: none;
    user-select: none;
    top: 5rem;
    left: 0rem;
    transition: left .2s, -webkit-transform .2s;
    transition: transform .2s, left .2s;
    transition: transform .2s, left .2s, -webkit-transform .2s;
    background-color: #474649;
    // border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0px 66px 16px 0px rgb(81, 81, 81) !important;
    // box-shadow: 0 3px 5px rgba(0, 0, 0, .02), 0 0 2px rgba(0, 0, 0, .05), 0 1px 4px rgba(0, 0, 0, .08);
}

/* Reduce scrollbar width for WebKit browsers */
.layout-sidebar::-webkit-scrollbar {
    width: 6px;
    /* Set the width of the scrollbar */
}

.layout-sidebar::-webkit-scrollbar-thumb {
    background: #888;
    /* Scrollbar color */
    border-radius: 4px;
    /* Rounded edges */
}

.layout-sidebar::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color on hover */
}

/* For Firefox */
.layout-sidebar {
    scrollbar-width: thin;
    /* Set thin scrollbar */
    scrollbar-color: #888 #474649;
    /* Thumb color and track color */
}

.layout-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        &.layout-menuitem-category {
            margin-top: 0.75rem;

            &:first-child {
                margin-top: 0;
            }
        }

        .layout-menuitem-root-text {
            text-transform: uppercase;
            // color:var(--surface-900);
            color: #fff;
            font-weight: 600;
            margin-bottom: 2rem;
            font-size: 0.875rem;
        }

        a {
            cursor: pointer;
            text-decoration: none;
            display: flex;
            align-items: center;
            // color:var(--text-color);
            color: #fff;
            transition: color $transitionDuration;
            border-radius: $borderRadius;
            padding: 0.75rem 1rem;
            transition: background-color 0.15s;
            margin-top: 0.5rem;

            span {
                margin-left: 0.5rem;
                font-weight: 400;
            }

            .menuitem-toggle-icon {
                margin-left: auto;
            }

            &:focus {
                @include focused-inset();
            }

            &:hover {
                background-color: var(--surface-hover);
                color: #474649;
                margin-top: 0.5rem;

            }

            &.router-link-exact-active {
                font-weight: 700;
                // color: var(--primary-color);
                background-color: #f8a91b;
                color: #000000;
                margin-top: 0.71rem;
            }

            .p-badge {
                margin-left: auto;
            }
        }

        &.active-menuitem {
            >a {
                .menuitem-toggle-icon {
                    &:before {
                        content: "\e933";
                    }
                }
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            &.layout-submenu-wrapper-enter {
                max-height: 0;
            }

            &.layout-submenu-wrapper-enter-active {
                overflow: hidden;
                max-height: 1000px;
                transition: max-height 1s ease-in-out;
            }

            &.layout-submenu-wrapper-enter-done {
                transform: none;
            }

            &.layout-submenu-wrapper-exit {
                max-height: 1000px;
            }

            &.layout-submenu-wrapper-exit-active {
                overflow: hidden;
                max-height: 0;
                transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
            }

            ul {
                padding-left: 1rem;
            }
        }
    }
}